<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{ name: 'DashboardHome' }"
              ><i class="fa fa-home"></i
            ></b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'MainGroupbuyingsList' }"
              >總公司團購管理</b-breadcrumb-item
            >
            <b-breadcrumb-item active>總公司庫存品開團</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row">
        <div class="col-12 col-xl-9">

          <h4 class="mb-2 font-weight-bold">總公司庫存品開團</h4>

          <section class="mb-5">
            <div class="h5">Step 1: 請編輯團購設定</div>

            <div class="mb-4">
              <b-form-group>
                <b-form-select
                  class="form-control form-control-sm col-12 col-xl-6"
                  v-model="currentProductId"
                  @change="handleChangeProduct"
                  :disabled="!productOptions || productOptions.length === 0"
                >
                  <b-form-select-option
                    v-if="productOptions && productOptions.length > 1"
                    :value="null"
                    disabled
                  >
                    請選擇商品名稱
                  </b-form-select-option>
                  <b-form-select-option
                    v-for="productOption in productOptions"
                    :key="productOption.id"
                    :value="productOption.id"
                  >
                    {{ productOption.title }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
              <b-spinner
                style="width: 2rem; height: 2rem;"
                v-if="isFetchProductInfo"
                variant="secondary"
              ></b-spinner>
              <div class="mb-4 product-info" v-if="showProductInfo">
                <div>店內碼: {{ productInfo.barcode }}</div>
                <div>國際碼: {{ productInfo.ian }}</div>
                <div>原價: {{ productInfo.orig_price }}</div>
                <div>售價: {{ productInfo.sale_price }}</div>
              </div>
            </div>
          </section>

          <section class="mb-5">
            <div class="h5">Step 2: 請選擇分店</div>
            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="團購分店"
              label-for="input-1"
            >
              <b-form-radio-group
                id="checkbox-group-2"
                v-model="branchGroup"
                stacked
                :disabled="currentProductId === null || isSaving"
              >
                <b-form-radio :value="BRANCH_GROUP.ALL">
                  <div>全部分店</div>
                </b-form-radio>
                <b-form-radio :value="BRANCH_GROUP.ONE">
                  <b-form-select
                    v-model="branch_id"
                    placeholder="選擇分店"
                    size="sm"
                    :disabled="branchGroup !== BRANCH_GROUP.ONE"
                  >
                    <b-form-select-option :value="null" disabled
                      >選擇分店</b-form-select-option
                    >
                    <b-form-select-option
                      v-for="branch in branchOptions"
                      :value="branch.value"
                      :key="branch.value"
                      >{{ branch.text }}</b-form-select-option
                    >
                  </b-form-select>
                </b-form-radio>
                <b-form-radio :value="BRANCH_GROUP.EXCEL">
                  <div class="form-group d-inline-flex align-items-center">
                    <div class="flex-shrink-0 mr-2 mb-0">上傳分店</div>
                    <b-form-file plain @input="uploadBranches"></b-form-file>
                  </div>

                  <b-button
                    size="sm"
                    variant="outline-primary"
                    href="/excel/branch_list.xlsx"
                    ><i class="fa fa-file-text"></i>下載範例檔案</b-button
                  >
                </b-form-radio>
              </b-form-radio-group>
              <!-- <div v-else>
                <BranchesTable :branches="targetBranches" />
              </div> -->
            </b-form-group>
          </section>

          <section class="mb-8">
            <div class="col-12 col-xl-8">
              <!-- 當 branchGroup === excel 時，這整個區塊都要隱藏 -->
              <div v-if="branchGroup !== BRANCH_GROUP.EXCEL">
                <!-- 庫存 input -->
                <b-form-group
                  label-cols="7"
                  label-cols-lg="2"
                  label-size="sm"
                  label="庫存共"
                  label-for="total_sku_quantity"
                >
                  <b-form-input
                    id="total_sku_quantity"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="number"
                    style="width: 80px"
                    v-model="form.total_sku_quantity"
                    :formatter="(value) => (value < 1 ? 0 : Math.round(value))"
                    :state="v$.form.total_sku_quantity.$error ? false : null"
                    :disabled="readOnlyForInput || isSaving"
                    @click="handleSelect"
                  ></b-form-input>

                  件
                  <b-form-invalid-feedback :state="!v$.form.total_sku_quantity.lengthSix.$invalid">
                      數字上限為六位數
                  </b-form-invalid-feedback>

                  <!-- <b-form-invalid-feedback
                    :state="!v$.form.total_sku_quantity.less.$invalid"
                  >
                    msg for 庫存
                  </b-form-invalid-feedback> -->
                </b-form-group>

                <!-- 每人最高購買上限 input -->
                <b-form-group
                  label-cols="8"
                  label-cols-lg="2"
                  label-size="sm"
                  label="每人最高購買數"
                  label-for="once_max_quantity"
                >
                  <b-form-input
                    id="once_max_quantity"
                    class="col-lg-2 mb-2 mr-sm-2 mb-sm-0"
                    type="number"
                    v-model="form.once_max_quantity"
                    :formatter="(value) => (value < 1 ? 0 : Math.round(value))"
                    :state="v$.form.once_max_quantity.$error ? false : null"
                    :disabled="isSaving"
                    @click="handleSelect"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    class="invalid-wording-right"
                    :state="!v$.form.once_max_quantity.less.$invalid"
                  >
                    需小於商品總數上限
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback
                    class="invalid-wording-right"
                    :state="!v$.form.once_max_quantity.lengthSix.$invalid"
                  >
                    數字上限為六位數
                  </b-form-invalid-feedback>
                </b-form-group>

                <!-- 每人最高購買上限 input -->
                <b-form-group
                  label-cols="8"
                  label-cols-lg="2"
                  label-size="sm"
                  label="每人最低購買數"
                  label-for="once_quantity"
                >
                  <b-form-input
                    id="once_quantity"
                    class="col-lg-2 mb-2 mr-sm-2 mb-sm-0"
                    type="number"
                    v-model="form.once_quantity"
                    :formatter="(value) => (value < 1 ? 0 : Math.round(value))"
                    :state="v$.form.once_quantity.$error ? false : null"
                    :disabled="isSaving"
                    @click="handleSelect"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    class="invalid-wording-right"
                    :state="!v$.form.once_quantity.less.$invalid"
                  >
                    需小於每人最高購買數
                  </b-form-invalid-feedback>
                </b-form-group>

                <!-- 下單數量遞增數 input -->
                <b-form-group
                  label-cols="8"
                  label-cols-lg="2"
                  label-size="sm"
                  label="下單數量遞增數"
                  label-for="once_increase_quantity"
                >
                  <b-form-input
                    id="once_increase_quantity"
                    class="col-lg-2 mb-2 mr-sm-2 mb-sm-0"
                    type="number"
                    v-model="form.once_increase_quantity"
                    :formatter="(value) => (value < 1 ? 0 : Math.round(value))"
                    :state="v$.form.once_increase_quantity.$error ? false : null"
                    :disabled="!canEditOnceIncreaseQuantity || isSaving"
                    @click="handleSelect"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    class="invalid-wording-right"
                    :state="!v$.form.once_increase_quantity.less.$invalid"
                  >
                    下單遞增數與最低購買數加總不可大於最高購買數
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="開始時間"
                  label-for="input-1"
                >
                  <div class="d-flex">
                    <div class="w-50">
                      <datepicker
                        class="mb-2"
                        placeholder="Select Date"
                        v-model="form.start_at.date"
                        bootstrap-styling
                        format="yyyy-MM-dd"
                        :language="zh"
                        :disabledDates="disabledDates || isSaving"
                      ></datepicker>
                      <b-form-invalid-feedback
                        :state="!v$.form.start_at.date.required.$error"
                      >
                        此欄位為必填
                      </b-form-invalid-feedback>
                    </div>

                    <div class="ml-2 w-50">
                      <vue-timepicker
                        format="HH:mm"
                        v-model="form.start_at.time"
                        :input-class="['form-control']"
                      ></vue-timepicker>
                      <b-form-invalid-feedback
                        :state="
                          !v$.form.start_at.time.HH.$error ||
                          !v$.form.start_at.time.mm.$error
                        "
                      >
                        此欄位為必填
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="截止時間"
                  label-for="input-1"
                  description="*達截止時間系統將自動結團"
                >
                  <div class="d-flex">
                    <div class="w-50">
                      <datepicker
                        class="mb-2"
                        placeholder="Select Date"
                        v-model="form.end_at.date"
                        bootstrap-styling
                        format="yyyy-MM-dd"
                        :language="zh"
                        :input-class="{
                          'is-invalid': v$.form.start_at.$invalid,
                        }"
                        :disabledDates="disabledDates || isSaving"
                      ></datepicker>
                      <b-form-invalid-feedback :state="!v$.form.end_at.date.$error">
                        此欄位為必填
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback
                        :state="!v$.form.start_at.date.beforeEndAt.$invalid"
                      >
                        截止時間必須大於開團時間
                      </b-form-invalid-feedback>
                    </div>

                    <div class="ml-2 w-50">
                      <vue-timepicker
                        :input-class="[
                          'form-control',
                          {
                            'is-invalid':
                              v$.form.start_at.time.HH.$error ||
                              v$.form.start_at.time.mm.$error ||
                              v$.form.start_at.date.beforeEndAt.$invalid,
                          },
                        ]"
                        v-model="form.end_at.time"
                      ></vue-timepicker>
                      <b-form-invalid-feedback
                        :state="
                          !v$.form.end_at.time.HH.$error ||
                          !v$.form.end_at.time.mm.$error
                        "
                      >
                        此欄位為必填
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </b-form-group>
              </div>


              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="員工編號"
                label-for="tracking_code"
              >
                <b-form-input
                  id="tracking_code"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  style="width: 170px"
                  v-model="form.tracking_code"
                  :formatter="
                    (value) => (value.length > 6 ? value.slice(0, 6) : value)
                  "
                  :state="v$.form.tracking_code.$error ? false : null"
                  :disabled="isSaving"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="v$.form.tracking_code.required.$invalid"
                >
                  此欄位為必填
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  v-if="v$.form.tracking_code.lengthSix.$invalid"
                >
                  需為6碼數字
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                label-cols="6"
                label-cols-lg="2"
                label-size="sm"
                label="順序"
                label-for="order"
              >
                <b-form-input
                  id="order"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  style="width: 170px"
                  v-model="form.order"
                  :state="v$.form.order.$error ? false : null"
                  :disabled="isSaving"
                  type="number"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-if="v$.form.order.required.$invalid"
                >
                  此欄位為必填
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  v-if="v$.form.order.lengthTwo.$invalid"
                >
                  需為2碼數字
                </b-form-invalid-feedback>
              </b-form-group>
              </div>
          </section>

        </div>
      </div>

      <div class="d-flex justify-content-center buttons__action">
        <b-button
          class="mr-3"
          variant="success"
          @click="saveAndBackToUpdateImages"
          :disabled="isSaving"
        >
          設定<br class="d-lg-none" />商品圖片
        </b-button>

        <b-button
          class="mr-3"
          variant="success"
          @click="saveDraft"
          :disabled="isSaving"
        >
          儲存草稿
        </b-button>

        <b-button
          variant="success"
          @click="save"
          :disabled="isSaving"
        >
          確認開團
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";
import { format, isBefore, set, subDays, getHours, getMinutes } from "date-fns";
import { zh } from "vuejs-datepicker/dist/locale";
import branchApi from "@/apis/branch";
// import groupApi from "@/apis/group";
import groupbuyApi from "@/apis/groupbuy";
import mainGroupbuyApi from "@/apis/main-groupbuyings";
import productApi from "@/apis/product";
import _ from "lodash";

const BRANCH_GROUP = {
  ALL: "all",
  ONE: "one",
  EXCEL: "excel",
};

const MODE = {
  CREATE: "create",
  EDIT: "edit",
  READONLY: "readonly",
};

export default {
  components: { Datepicker, VueTimepicker },
  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),
  data: () => {
    return {
      zh,
      MODE,
      BRANCH_GROUP,
      branchGroup: BRANCH_GROUP.ALL,
      branchId: null,
      branch_id: null,
      excelBranches: [],
      uploadedPayload: [],
      currentProductId: null,
      product: null,
      productOptions: null,
      groupOptions: null,
      targetBranches: [],
      firstBranch: null,
      secondBranch: null,
      thirdBranch: null,
      firstLayerBranches: [],
      secondLayerBranches: [],
      thirdLayerBranches: [],
      isFetchFirstLayerBranches: false,
      isFetchSecondLayerBranches: false,
      isFetchThirdLayerBranches: false,
      isFetchGroup: false,
      canDivideTotal: false, // 用來決定是否可算分組組數,
      disabledDates: { to: subDays(new Date(), 1) },
      form: {
        branch_id: null,
        group_ids: [],
        product_ids: null,
        total_sku_quantity: 1,
        once_max_quantity: 1, // 每人最高購買數
        once_quantity: 1, // 每人最低購買數
        once_increase_quantity: 1, // 購買遞增數
        // is_force_confirmed: false, // 強制結單
        max_quantity: 0, // 商品總數上限
        tracking_code: null,
        // group_type_ids: [],
        start_at: {
          date: new Date(),
          time: {
            HH: "00",
            mm: "00",
          },
        },
        end_at: {
          date: new Date(),
          time: {
            HH: "23",
            mm: "59",
          },
        },
        // payday_period: 0,
        order: "50",
      },
      maxSkuQuantity: 0, //用做最高購買數不可大於最高群組配量的參考
      minSkuQuantity: 0, //用做最低購買數不可大於最低群組配量的參考
      canEditOnceIncreaseQuantity: false,
      isSaving: false,
      productInfo: {
        id: null,
        name: null,
        barcode: null, // 店內碼
        ian: null, // 國際碼
        rating: null, // 批價
        bargain_price: null, // 特價
        unit_price: null, // 單價
        discount: null, // 組合優惠
      },
      showProductInfo: false,
      isFetchProductInfo: false,
      code: null,
      isFetchProduct: false,
      mainGroupbuying: null,
      is_draft: false,
    };
  },
  validations() {
    return {
      form: {
        product_ids: { required },
        total_sku_quantity: {
          required,
          lengthSix: () => {
            return (
              Number(this.form.total_sku_quantity) <= 999999
            );
          },
        },
        once_max_quantity: {
          less: () => {
            return this.form.max_quantity
              ? Number(this.form.max_quantity) >=
                  Number(this.form.once_max_quantity)
              : true;
          },
          lengthSix: () => {
            return (
              Number(this.form.once_max_quantity) <= 999999
            );
          },
        },
        once_quantity: {
          less: () => {
            return this.form.once_max_quantity
              ? Number(this.form.once_max_quantity) >=
                  Number(this.form.once_quantity)
              : true;
          },
        },
        once_increase_quantity: {
          required,
          less: () => {
            if (+this.form.once_max_quantity === +this.form.once_quantity) {
              return true;
            }
            return +this.form.once_increase_quantity + +this.form.once_quantity <= +this.form.once_max_quantity;
          },
        },
        start_at: {
          date: {
            required,
            beforeEndAt: () => {
              const startDate = set(new Date(this.form.start_at.date), {
                hours: this.form.start_at.time.HH,
                minutes: this.form.start_at.time.mm,
              });

              const endDate = set(new Date(this.form.end_at.date), {
                hours: this.form.end_at.time.HH,
                minutes: this.form.end_at.time.mm,
              });

              return isBefore(startDate, endDate);
            },
          },
          time: {
            HH: { required },
            mm: { required },
          },
        },
        end_at: {
          date: { required },
          time: {
            HH: { required },
            mm: { required },
          },
        },
        tracking_code: {
          required,
          lengthSix: () => {
            if (!this.form.tracking_code) return true;
            return (
              this.form.tracking_code.length === 6 &&
              /^[0-9]*$/.test(this.form.tracking_code)
            );
          },
        },
        order: {
          required,
          lengthTwo: () => {
            return parseInt(this.form.order) < 100
          },
        },
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      currentBranch: (state) => state.currentBranch,
      ...mapGetters("general", ["branchOptions"]),
    }),
    mapFirstLayerBranches() {
      return this.mapBranches(this.firstLayerBranches);
    },
    mapSecondLayerBranches() {
      return this.mapBranches(this.secondLayerBranches);
    },
    mapThirdLayerBranches() {
      return this.mapBranches(this.thirdLayerBranches);
    },
    mode() {
      if (! this.product) {
        return MODE.CREATE;
      }

      if (this.product.main_groupbuyings && this.product.main_groupbuyings.length >= 1) {
        return MODE.EDIT;
      }

      return MODE.CREATE;
    },
    readOnlyForInput() {
      if (this.branchGroup == BRANCH_GROUP.EXCEL) {
        return true;
      }

      return false;
    },
  },
  mounted: function () {
    // if (this.$route.query.product_id) {
    //   this.currentProductId = this.$route.query.product_id;
    //   this.form.product_ids = this.$route.query.product_id;
    // }

    this.branchId = this.$route.params.branch_id;
    // this.getProductList();
    this.getUngroupbuyingProducts();
    this.getFirstLayerBranches();
  },
  watch: {
    currentProductId: function (val) {
      this.currentProductId = val;
      this.form.product_ids = [val];
      if (this.productOptions) {
        this.product = this.productOptions.find((p) => p.id == val);
      }
    },
    product: function () {
      this.handleProductModified();
    },
    maxSkuQuantity: function () {
      this.form.once_max_quantity = this.maxSkuQuantity;
    },
    "form.once_max_quantity": function () {
      this.handleCanEditOnceIncreaseQuantity();
    },

    "form.once_quantity": function () {
      this.handleCanEditOnceIncreaseQuantity();
    },

    "form.order": function () {
      this.form.order = this.form.order.replace(/^0+(?=\d)/, "");

      // 確保輸入的值為數字，如果不是，則設置為空字串
      const parsedValue = parseFloat(this.form.order);
      if (isNaN(parsedValue)) {
        this.form.order = "";
      }
    }
  },
  methods: {
    /**
     * 最高購買數 != 最低購買數，數量遞增數需變成可編輯狀態
     * 最高購買數 == 最低購買數，數量遞增數需為停用，且設為1
     */
    handleCanEditOnceIncreaseQuantity () {
      if (parseInt(this.form.once_max_quantity) != parseInt(this.form.once_quantity)) {
        this.canEditOnceIncreaseQuantity = true;
      }

      if (parseInt(this.form.once_max_quantity) == parseInt(this.form.once_quantity)) {
        this.canEditOnceIncreaseQuantity = false;
        this.form.once_increase_quantity = 1;
      }
    },

    handleSetBranchValue() {
      if (this.branchGroup === BRANCH_GROUP.ALL) {
        delete this.form.branch_ids;
        this.form.is_all = true;
      }
      if (this.branchGroup === BRANCH_GROUP.ONE) {
        this.form.branch_ids = [this.branch_id];
      }
      if (this.branchGroup === BRANCH_GROUP.EXCEL) {
        this.form.branch_ids = this.excelBranches;
      }
    },

    async handleProductModified() {
      this.updateProductInfo(this.product);

      // 代表沒有拿到草稿，這商品是新的
      if (!this.product.main_groupbuyings || this.product.main_groupbuyings.length === 0) {
        console.debug('Product not draft', this.product);
        this.is_draft = false;
        return;
      }

      await this.getMainGroupbuying(this.product.main_groupbuyings[0]);
      this.is_draft = true;

      /**
       * 商品是否有mainGroupbuying
       *  Y: 新商品要建立
       *      按下儲存草稿是建立草稿
       *      按下確認開團是建立草稿+start mainGroupbuying
       *      預設跑去 [全部分店]
       *  N: 已經有草稿
       *      按下儲存草稿是編輯草稿
       *      按下確認開團是編輯草稿+start mainGroupbuying
       *      依照mainGroupbuying.branch_mode 就能知道是哪一種 (all, excel, one)
       *
       *   建立草稿就會拉 branchMainGroupbuying, 所以編輯草稿時, 需要同步關聯branchMainGroupbuying
       */

       // 如果有 mainGroupbuying
    },

    resetMaxMinSkuQuantity () {
      let me = this;

      this.maxSkuQuantity =
      _.chain(this.groupOptions)
      .filter(function (go) {
        if (me.form.group_ids.includes(go.id)) {
          return go;
        }
      })
      .maxBy(function (go) {
        return parseInt(go.sku_quantity);
      })
      .value()
      .sku_quantity ?? 0;
      this.maxSkuQuantity = parseInt(this.maxSkuQuantity);

      this.minSkuQuantity =
      _.chain(this.groupOptions)
      .filter(function (go) {
        if (me.form.group_ids.includes(go.id)) {
          return go;
        }
      })
      .minBy(function (go) {
        return parseInt(go.sku_quantity);
      })
      .value()
      .sku_quantity ?? 0;
      this.minSkuQuantity = parseInt(this.minSkuQuantity);
    },

    handleGroupChange() {
      let me = this;

      this.resetMaxMinSkuQuantity();

      let total = 0;
      for (let i = 0; i < this.groupOptions.length; i++) {
        if (!this.form.group_ids.includes(this.groupOptions[i].id)) {
          continue;
        }

        total += parseInt(this.groupOptions[i].sku_quantity);
      }

      this.canDivideTotal = false;
      this.form.total_sku_quantity = total;

      this.$nextTick(function () {
        me.canDivideTotal = true;
      });
    },

    handleSelect(e) {
      e.target.select();
    },

    async getMainGroupbuying(id) {
      try {
        this.isSaving = true;

        const { data } = await mainGroupbuyApi.getGroupbuying(id);
        this.mainGroupbuying = data.data;

        console.debug('mainGroupbuying', this.mainGroupbuying);

        // Restore branch mode
        if (this.mainGroupbuying.branch_mode) {
          this.branchGroup = this.mainGroupbuying.branch_mode;
        } else {
          this.branchGroup = BRANCH_GROUP.ALL;
          this.branch_id = null;
        }

        if (this.mainGroupbuying.branch_mode === BRANCH_GROUP.ONE) {
          this.branch_id = this.mainGroupbuying.branches[0]?.id ?? null;
        } else {
          this.branch_id = null;
        }

        // Restore total sku quantity
        this.form.total_sku_quantity = this.mainGroupbuying.max_quantity;
        this.form.once_max_quantity = this.mainGroupbuying.once_max_quantity;
        this.form.once_quantity = this.mainGroupbuying.once_quantity;
        this.form.once_increase_quantity = this.mainGroupbuying.once_increase_quantity;
        this.form.tracking_code = this.mainGroupbuying.tracking_code;

        const startOfToday = new Date().setHours(0, 0, 0, 0);
        const endOfToday = new Date().setHours(23, 59, 59, 999);

        // Start at
        let startAt = new Date(this.mainGroupbuying.start_at);
        startAt = startAt > startOfToday ? startAt : startOfToday;

        this.form.start_at.date = startAt;
        this.form.start_at.time.HH = String(getHours(startAt)).padStart(2,"0");
        this.form.start_at.time.mm = String(getMinutes(startAt)).padStart(2,"0");

        // End at
        let endAt = new Date(this.mainGroupbuying.end_at);
        endAt = endAt > startAt ? endAt : endOfToday;

        this.form.end_at.date = endAt;
        this.form.end_at.time.HH = String(getHours(endAt)).padStart(2, "0");
        this.form.end_at.time.mm = String(getMinutes(endAt)).padStart(2, "0");
      } catch (err) {
        console.error(err);
      } finally {
        this.isSaving = false;
      }
    },

    async getUngroupbuyingProducts() {
      try {
        const { data } = await productApi.getUngroupbuyingProducts({
          branch_id: this.form.branch_id,
        });

        this.productOptions = data.data;

        if (this.$route.query.product_id) {
          this.currentProductId = this.$route.query.product_id;
        }
      } catch (err) {
        console.error(err);
        this.$swal("錯誤", "查無未開團商品資料", "error")
      }
    },
    async save() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }

      const form = this.prepareForm();

      if (form.branch_mode == BRANCH_GROUP.EXCEL && form.branch_ids.length === 0) {
        this.$swal("失敗", "請選擇檔案", "error");
        return;
      }

      if (form.branch_mode == BRANCH_GROUP.ONE && form.branch_ids.length !== 1) {
        this.$swal("失敗", "請選擇開團分店", "error");
        return;
      }

      // 開團時，『最高購買數』、『最低購買數』、『數量遞增數』皆需 > = 1
      if (! (parseInt(this.form.once_max_quantity) >= 1 && parseInt(this.form.once_quantity) >= 1 && parseInt(this.form.once_increase_quantity) >= 1)) {
        this.$swal("失敗", "開團時，『最高購買數』、『最低購買數』、『數量遞增數』皆需大於等於1", "error");
        return;
      }

      if (this.product.images.length < 2) {
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: "最少需上傳 2 張商品圖片",
        });
        return;
      }

      try {
        this.isSaving = true;

        let { data } = this.is_draft
          ? await groupbuyApi.updateForMainStore(this.mainGroupbuying.id, form)
          : await groupbuyApi.createForMainStore(form)
        ;

        await groupbuyApi.startForMainStore(data.data.id);

        this.$swal('開團成功', '', 'success');

        this.$router.push({
          name: "MainGroupbuyingsList",
          query: { tab: "going" },
        });
      } catch (err) {
        let errMsg = err.response.data.message ?? "開團失敗";
        this.$swal("失敗", ` ${errMsg}`, "error");
        this.$router.push({
          name: "MainGroupbuyingDraft",
          query: {
            product_id: this.currentProductId,
          },
        });
      } finally {
        this.isSaving = false;
      }
    },

    prepareForm() {
      let branchIds = [];

      if (this.branchGroup == BRANCH_GROUP.EXCEL) {
        branchIds = this.excelBranches;
      } else if (this.branchGroup == BRANCH_GROUP.ONE && this.branch_id) {
        branchIds = [this.branch_id];
      }

      let items = [];
      if (this.branchGroup == BRANCH_GROUP.ALL || this.branchGroup == BRANCH_GROUP.ONE) {
        let startAt = format(
          set(new Date(this.form.start_at.date), {
            hours: Number(this.form.start_at.time.HH),
            minutes: Number(this.form.start_at.time.mm),
            seconds: 0,
          }),
          "yyyy-MM-dd HH:mm:ss"
        );
        let endAt = format(
          set(new Date(this.form.end_at.date), {
            hours: this.form.end_at.time.HH,
            minutes: this.form.end_at.time.mm,
            seconds: 59,
          }),
          "yyyy-MM-dd HH:mm:ss"
        );

        items.push({
          start_at: startAt,
          end_at: endAt,
          total_sku_quantity: this.form.total_sku_quantity,
          once_max_quantity: this.form.once_max_quantity,
          once_quantity: this.form.once_quantity,
          once_increase_quantity: this.form.once_increase_quantity,
        });
      } else if (this.branchGroup == BRANCH_GROUP.EXCEL) {
        items = this.uploadedPayload;
      }

      const form = {
        ...this.form,
        branch_ids: branchIds,
        is_all: this.branchGroup == BRANCH_GROUP.ALL,
        items: items,
        branch_mode: this.branchGroup,
      };

      return form;
    },

    /*
     * 編輯草稿＆設定開團資料兩頁的『設定商品圖片』的按鈕，要先作『儲存草稿』的功能
     * 若有錯誤，就不讓他去『設定商品圖片』並提示相關錯誤訊息
     */
    async saveAndBackToUpdateImages() {
      try {
          if (!this.currentProductId) {
          this.$swal('提醒', '請先選擇商品', 'warning');
          return;
        }

        const form = this.prepareForm();

        if (form.branch_mode == BRANCH_GROUP.EXCEL && form.branch_ids.length === 0) {
          this.$swal("失敗", "請選擇檔案", "error");
          return;
        }

        if (form.branch_mode == BRANCH_GROUP.ONE && form.branch_ids.length !== 1) {
          this.$swal("失敗", "請選擇開團分店", "error");
          return;
        }

        this.isSaving = true;

        if (this.is_draft) {
          await groupbuyApi.updateForMainStore(this.mainGroupbuying.id, form)
        } else {
          await groupbuyApi.createForMainStore(form)
        }
        this.$router.push({
          name: "MainGroupbuyingUpdateImage",
          params: {
            id: this.currentProductId,
          }
        });
      } catch (err) {
        console.error(err);
        this.$swal("失敗", "新增草稿失敗", "error");
      } finally {
        this.isSaving = false
      }
    },

    async saveDraft() {
      if (!this.currentProductId) {
        this.$swal('提醒', '請先選擇商品', 'warning');
        return;
      }

      const form = this.prepareForm();

      if (form.branch_mode == BRANCH_GROUP.EXCEL && form.branch_ids.length === 0) {
        this.$swal("失敗", "請選擇檔案", "error");
        return;
      }

      if (form.branch_mode == BRANCH_GROUP.ONE && form.branch_ids.length !== 1) {
        this.$swal("失敗", "請選擇開團分店", "error");
        return;
      }

      try {
        this.isSaving = true;

        if (this.is_draft) {
          await groupbuyApi.updateForMainStore(this.mainGroupbuying.id, form)
        } else {
          await groupbuyApi.createForMainStore(form)
        }

        this.$swal('成功', '草稿儲存成功', 'success');
      } catch (err) {
        console.error(err);
        this.$swal("失敗", "儲存草稿失敗", "error");
      } finally {
        this.isSaving = false;
      }
    },

    async getFirstLayerBranches() {
      this.isFetchFirstLayerBranches = true;
      const branches = await this.getBelowBranches(this.currentBranch.id);
      this.firstLayerBranches = branches;
      if (branches.length > 1) {
        this.firstLayerBranches = branches.filter(
          (branch) => branch.id !== this.currentBranch.id
        );
        this.form.branch_id = null;
      } else {
        this.firstBranch = this.mapFirstLayerBranches[0];
        this.form.branch_id = this.currentBranch.id;
      }

      this.isFetchFirstLayerBranches = false;
    },
    async handleFirstBranch(branch) {
      this.isFetchSecondLayerBranches = true;
      const branches = await this.getBelowBranches(branch.id);
      if (branches.length > 1) {
        this.secondLayerBranches = branches.filter(
          (branch) => branch.id !== this.firstBranch.id
        );
        this.form.branch_id = null;
      } else {
        this.secondLayerBranches = [];
        this.form.branch_id = branch.id;
      }
      this.isFetchSecondLayerBranches = false;
    },
    async handleSecondBranch(branch) {
      this.isFetchThirdLayerBranches = true;
      const branches = await this.getBelowBranches(branch.id);
      this.thirdLayerBranches = branches;
      if (branches.length > 1) {
        this.thirdLayerBranches = branches.filter(
          (branch) => branch.id !== this.secondBranch.id
        );
        this.form.branch_id = null;
      } else {
        this.form.branch_id = branch.id;
      }
      this.isFetchThirdLayerBranches = false;
    },
    async getBelowBranches(branch_id) {
      try {
        const { data } = await branchApi.getCurrentBelowBranches({
          branch_id,
        });

        return data.data.filter(
          (branch) => branch.org_id === this.organization.id
        );
      } catch (error) {
        this.$swal("失敗", error.response.data.message, "error");
        return;
      }
    },
    mapBranches(branches) {
      return branches
        .sort((a, b) => a.level - b.level)
        .sort((a, b) => {
          // if "level" is the same, order by "order"
          if (a.level === b.level) {
            return a.order - b.order;
          }
        })
        .map((branch) => {
          let branchName = `${branch.branch_code} ${branch.name}`;
          if (branch.level === 2) {
            branchName = `- ${branch.branch_code} ${branch.name}`;
          }
          if (branch.level === 3) {
            branchName = `- - ${branch.branch_code} ${branch.name}`;
          }

          return {
            ...branch,
            value: branch.id,
            name: branchName,
          };
        });
    },
    handleChangeProduct(event) {
      const selectedProduct = this.productOptions.find(
        (opt) => opt.id == event
      );

      if (selectedProduct.groupbuyings.length >= 1) {
        this.$router.push({
          name: "MainGroupbuyingDraft",
          query: {
            product_id: event,
          },
        });
      }

      this.groupOptions = null;
    },

    async uploadBranches(file) {
      let formData = new FormData();
      formData.append("file", file);

      try {
        const {
          data: { data },
        } = await groupbuyApi.uploadBranchesByExcel(formData);

        this.uploadedPayload = data.results;
        this.excelBranches = data.results.map((b) => b.branch_id);
      } catch (e) {
        console.error(e);
      }
    },

    async handleDelete() {
      if (!this.currentProductId) {
        this.$swal("失敗", "請先選擇商品", "error");
        return;
      }

      this.$swal({
        title: "<strong>你確定要刪除嗎?</strong>",
        type: "warning",
        html: `
          <div class="d-block">
            <div class="my-3">
              <div>商品名稱：${this.product.title}</div>
            </div>
            <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
          </div>
        `,
        confirmButtonText: "確定刪除",
        cancelButtonText: "不刪除",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        reverseButtons: true,
      }).then(async (result) => {
        if (!result.value) {
          return;
        }

        try {
          await groupbuyApi.deleteGroupbuyingsByProductForce(
            this.currentProductId
          );
          this.$swal.fire({
            icon: "success",
            text: "刪除商品成功",
          }).then(() => {
            window.location.reload();
          });
        } catch (err) {
          const message = err.response.data.message
          if (message.includes("No query results for model")) {
            this.$swal("失敗", '查無此商品', "error").then(() => {
              window.location.reload();
            });
          } else {
            this.$swal("失敗", err.response.data.message, "error");
          }
        }
      });
    },

    async updateProductInfo(product) {
      this.isFetchProductInfo = true
      this.showProductInfo = false
      const { data } = await groupbuyApi.getMainStoreGroupBuyProduct(product.skus[0].sku_serial_number);

      this.productInfo.id = data.id;
      this.productInfo.name = data.name;
      this.productInfo.barcode = data.poya_prod;
      this.productInfo.ian = data.prod;
      this.productInfo.orig_price = data.orig_price;
      this.productInfo.discount = data.combi_type;
      this.productInfo.sale_price = data.sale_price;
      this.isFetchProductInfo = false
      this.showProductInfo = true
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .custom-control,
::v-deep .custom-control-label {
  width: 100%;
}

.buttons__action {
  margin-bottom: 150px;
}

.custom-radio {
  margin-bottom: 4px;
}

.custom-radio .custom-control-label {
  width: auto;
}

@media screen and (max-width: 768px) {
  .buttons__action > .btn {
    flex: 1;
    line-height: 1.2;
  }
  .invalid-wording-right {
    width: 320%;
    text-align: end;
    float:right
  }
}
</style>
